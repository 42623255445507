// stylelint-disable primer/selector-no-utility
// stylelint-disable selector-max-type

.details-overlay[open] > summary::before {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 80;
  display: block;
  cursor: default;
  content: " ";
  background: transparent;
}

.details-overlay-dark[open] > summary::before {
  z-index: 99;
  background: $black-fade-50;
}
