// Need to target base styles
// stylelint-disable selector-max-compound-selectors, selector-no-qualifying-type, primer/selector-no-utility
// stylelint-disable selector-max-type
.markdown-body {
  // Images & Stuff
  img {
    max-width: 100%;
    // because we put padding on the images to hide header lines, and some people
    // specify the width of their images in their markdown.
    box-sizing: content-box;
    background-color: $bg-white;

    &[align=right] {
      padding-left: 20px;
    }

    &[align=left] {
      padding-right: 20px;
    }
  }

  .emoji {
    max-width: none;
    vertical-align: text-top;
    // Override `<img>` styles so Emjois don't clash with zebra striping in our tables
    background-color: transparent;
  }

  // Gollum Image Tags

  // Framed
  span.frame {
    display: block;
    overflow: hidden;

    > span {
      display: block;
      float: left;
      width: auto;
      padding: 7px;
      margin: 13px 0 0;
      overflow: hidden;
      border: 1px solid lighten($gray-300, 5%);
    }

    span img {
      display: block;
      float: left;
    }

    span span {
      display: block;
      padding: 5px 0 0;
      clear: both;
      color: $text-gray-dark;
    }
  }

  span.align-center {
    display: block;
    overflow: hidden;
    clear: both;

    > span {
      display: block;
      margin: 13px auto 0;
      overflow: hidden;
      text-align: center;
    }

    span img {
      margin: 0 auto;
      text-align: center;
    }
  }

  span.align-right {
    display: block;
    overflow: hidden;
    clear: both;

    > span {
      display: block;
      margin: 13px 0 0;
      overflow: hidden;
      text-align: right;
    }

    span img {
      margin: 0;
      text-align: right;
    }
  }

  span.float-left {
    display: block;
    float: left;
    margin-right: 13px;
    overflow: hidden;

    span {
      margin: 13px 0 0;
    }
  }

  span.float-right {
    display: block;
    float: right;
    margin-left: 13px;
    overflow: hidden;

    > span {
      display: block;
      margin: 13px auto 0;
      overflow: hidden;
      text-align: right;
    }
  }
}
