.Header {
  z-index: 32; // TODO: Figure out z-index system
  display: flex;
  padding: $spacer-3;
  font-size: $h5-size;
  line-height: $lh-default;
  color: $white-fade-70;
  background-color: $bg-gray-dark;
  align-items: center;
  flex-wrap: nowrap;
}

.Header-item {
  display: flex;
  margin-right: $spacer-3;
  align-self: stretch;
  align-items: center;
  flex-wrap: nowrap;
}

.Header-item--full {
  flex: auto;
}

.Header-link {
  font-weight: $font-weight-bold;
  color: $white;
  white-space: nowrap;

  &:hover,
  &:focus {
    color: $white-fade-70;
    text-decoration: none;
  }
}
