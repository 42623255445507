.breadcrumb-item {
  display: inline-block;
  margin-left: -0.35em;
  white-space: nowrap;
  list-style: none;

  &::after {
    padding-right: $em-spacer-5;
    padding-left: $em-spacer-5;
    color: $border-gray;
    content: "/";
  }

  &:first-child {
    margin-left: 0;
  }
}

.breadcrumb-item-selected {
  &::after {
    content: none;
  }
}
