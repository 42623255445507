.btn-mktg {
  display: inline-block;
  padding: $spacer-3 $spacer-4;
  font-size: $h5-size;
  font-weight: $font-weight-semibold;
  color: $white;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: $blue-mktg;
  border: 1px solid $blue-mktg;
  border-radius: $border-radius;
  transition: $transition-time / 2;
  appearance: none; // Corrects inability to style clickable `input` types in iOS.

  &:hover {
    text-decoration: none;
    background-color: $blue-500;
    border-color: $blue-500;
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2em rgba($blue-500, 0.3);
  }

  &:disabled,
  &.disabled {
    pointer-events: none; // Disable hover styles
    cursor: default;
    opacity: 0.65;
  }
}

.btn-primary-mktg {
  background-color: $green-mktg;
  border-color: $green-mktg;

  &:hover {
    background-color: $green-500;
    border-color: $green-500;
  }

  &:focus {
    box-shadow: 0 0 0 0.2em rgba($green-500, 0.3);
  }
}

.btn-large-mktg {
  padding: 20px $spacer-5;
  font-size: $h4-size;
}

.btn-outline-mktg {
  color: $blue-mktg;
  background-color: rgba($white, 0);
  border-color: rgba($blue-mktg, 0.5);

  &:hover {
    color: $blue-500;
    text-decoration: none;
    background-color: rgba($white, 0);
    border-color: rgba($blue-mktg, 1);
  }
}

@mixin btn-transparent-active {
  color: $gray-800;
  background-color: $white;
  background-image: none;
  border-color: $white;
}

.btn-transparent {
  color: $white;
  background-color: transparent;
  background-image: none;
  border: $border-width $border-style $white-fade-50;

  &:hover,
  &:active {
    @include btn-transparent-active;
  }
}
